import React from 'react';
import '../../details.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from "react-i18next";

import three from '../../images/cold-starters/3.png';








function CSthree({ closeModal }) {

    const { t } = useTranslation();
   
    const images = [
        three,
    ];
    
  return (
    <div className='modalBackgrounddetail'>
        <div className='modalContainerdetail'>
            <div className='titleCloseBtndetail'>
                <button onClick={() => closeModal(false)}> X </button>
            </div>

            
            
            <div className='body'>
            
                <p>{t('COLD GREEN PEA SOUP')}</p>
            </div>
            {/*<div className='footer'></div>*/}

            
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
            

            <div className='ingredients'>
               <strong>{t('Green pea | Tahini | Mint | Crispy bread | Muhammara & Labneh garnish | 200g')}</strong>
            </div>

        </div>
  </div>

  
  )
}

export default CSthree