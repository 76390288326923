import React from 'react';
import '../../details.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from "react-i18next";

import ten from '../../images/main-course/10.png';
import eleven from '../../images/main-course/11.png';
import twelve from '../../images/main-course/12.png';
import thirteen from '../../images/main-course/13.png';
import fourteen from '../../images/main-course/14.png';










function MCseven({ closeModal }) {

    const { t } = useTranslation();
   
    const images = [
        ten,
        eleven,
        twelve,
        thirteen,
        fourteen,
    ];
    
  return (
    <div className='modalBackgrounddetail'>
        <div className='modalContainerdetail'>
            <div className='titleCloseBtndetail'>
                <button onClick={() => closeModal(false)}> X </button>
            </div>

            
            
            <div className='body'>
            
                <p>{t('SAUCES')}</p>
            </div>
            {/*<div className='footer'></div>*/}

            <Slide>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[1]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[2]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[3]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[4]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
            </Slide>
            

            <div className='ingredients'>
               <strong>{t('RED WINE SAUCE | TABBOULEH DIP | RED CABBAGE KETCHUP GREEN HERB MAYO | CHILLI JAM | Each 50ml')}</strong>
            </div>

        </div>
  </div>

  
  )
}

export default MCseven