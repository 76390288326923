import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/cold-starters/1.png';
  import two from '../images/cold-starters/2.png';
  import three from '../images/cold-starters/3.png';
  import four from '../images/cold-starters/4.png';
  import five from '../images/cold-starters/5.png';


  import CSone from './ColdStarters/CSone';
  import CStwo from './ColdStarters/CStwo';
  import CSthree from './ColdStarters/CSthree';
  import CSfour from './ColdStarters/CSfour';
  import CSfive from './ColdStarters/CSfive';


  

const ColdStarters = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };
    
    const [openCSone, setOpenCSone] = useState(false);
    const [openCStwo, setOpenCStwo] = useState(false);
    const [openCSthree, setOpenCSthree] = useState(false);
    const [openCSfour, setOpenCSfour] = useState(false);
    const [openCSfive, setOpenCSfive] = useState(false);

     


    return (
        <>
        
        <Page>

        {openCSone && <CSone closeModal={setOpenCSone}/>}
        {openCStwo && <CStwo closeModal={setOpenCStwo}/>}
        {openCSthree && <CSthree closeModal={setOpenCSthree}/>}
        {openCSfour && <CSfour closeModal={setOpenCSfour}/>} 
        {openCSfive && <CSfive closeModal={setOpenCSfive}/>}

      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title={t('MEZZE')}
            subtitle={t('coldstarters')}
            after="8€"
            text={t('HUMMUS | BABA GHANOUSH | MUHAMMARA | LABNEH SPREAD | Each 150g / Add-on: Falafel | 100g / 4€')}
            media={
                <img
                src={one}
                alt="HUMMUS | BABA GHANOUSH | MUHAMMARA | LABNEH SPREAD | Each 150g / Add-on: Falafel | 100g / 4€"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenCSone(true)}
            />
        <ListItem
            title={t('BEEF TARTARE')}
            subtitle={t('coldstarters')}
            after="22€"
            text={t('Classic Beef Tartare | Butter | Homemade bread | 150g')}
            media={
                <img
                src={two}
                alt="Classic Beef Tartare | Butter | Homemade bread | 150g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenCStwo(true)}
            />  
        <ListItem
            title={t('COLD GREEN PEA SOUP')}
            subtitle={t('coldstarters')}
            after="11€"
            text={t('Green pea | Tahini | Mint | Crispy bread | Muhammara & Labneh garnish | 200g')}
            media={
                <img
                src={three}
                alt="Green pea | Tahini | Mint | Crispy bread | Muhammara & Labneh garnish | 200g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenCSthree(true)}
            /> 
        <ListItem
            title={t('FATTOUSH SALAD')}
            subtitle={t('coldstarters')}
            after="8€"
            text={t('Green Salad | Tomato concasse | Radish | Lemon-Olive oil dressing | 200g')}
            media={
                <img
                src={four}
                alt="Green Salad | Tomato concasse | Radish | Lemon-Olive oil dressing | 200g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenCSfour(true)}
            />
        <ListItem
            title={t('BULGUR TABBOULEH')}
            subtitle={t('coldstarters')}
            after="8€"
            text={t('Bulgur | Parsley | Coriander | Mint | Tomato concasse | Lemon-Olive oil dressing | 200g / Add-on: Prawns | Octopus | Salmon “Gravlax” | Each 100g / 14€')}
            media={
                <img
                src={five}
                alt="Bulgur | Parsley | Coriander | Mint | Tomato concasse | Lemon-Olive oil dressing | 200g / Add-on: Prawns | Octopus | Salmon “Gravlax” | Each 100g / 14€"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenCSfive(true)}
            />    
        
      </List>

      
    </Page>
        </>
    );
  };
  
  export default ColdStarters;