import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/hot-starters/1.png';
  import two from '../images/hot-starters/2.png';
  import three from '../images/hot-starters/3.png';
  import four from '../images/hot-starters/4.png';


  import HSone from './HotStarters/HSone';
  import HStwo from './HotStarters/HStwo';
  import HSthree from './HotStarters/HSthree';
  import HSfour from './HotStarters/HSfour';
  


  

const HotStarters = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };

    const [openHSone, setOpenHSone] = useState(false);
    const [openHStwo, setOpenHStwo] = useState(false);
    const [openHSthree, setOpenHSthree] = useState(false);
    const [openHSfour, setOpenHSfour] = useState(false);

     


    return (
        <>
        
        <Page>

        {openHSone && <HSone closeModal={setOpenHSone}/>}
        {openHStwo && <HStwo closeModal={setOpenHStwo}/>}
        {openHSthree && <HSthree closeModal={setOpenHSthree}/>}
        {openHSfour && <HSfour closeModal={setOpenHSfour}/>}

      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title={t('CRISPY LAMB BAR')}
            subtitle={t('hotstarters')}
            after="24€"
            text={t('Pulled Lamb | Pistachios | Pickled Onions | Red Cabbage Ketchup | Pomegranate Sauce | 220g')}
            media={
                <img
                src={one}
                alt="Pulled Lamb | Pistachios | Pickled Onions | Red Cabbage Ketchup | Pomegranate Sauce | 220g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenHSone(true)}
            />
        <ListItem
            title={t('LEBANON SAMBOUSEK')}
            subtitle={t('hotstarters')}
            after="12€"
            text={t('Puffed pastry | Vegetable mix | Greek yogurt & mint cream | 150g')}
            media={
                <img
                src={two}
                alt="Puffed pastry | Vegetable mix | Greek yogurt & mint cream | 150g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenHStwo(true)}
            />
        <ListItem
            title={t('BURNED ORANGE FREGULA')}
            subtitle={t('hotstarters')}
            after="22€"
            text={t('Fregula | Prawns | Bell-pepper cream | 270g')}
            media={
                <img
                src={three}
                alt="Fregula | Prawns | Bell-pepper cream | 270g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenHSthree(true)}
            />
        <ListItem
            title={t('CHARCOAL GRILLED OCTOPUS')}
            subtitle={t('hotstarters')}
            after="24€"
            text={t('Mibrasa grilled Octopus | Black Cauliflower Puree | Dry Cherry Tomato | Pickled Chilli | 220g')}
            media={
                <img
                src={four}
                alt="Mibrasa grilled Octopus | Black Cauliflower Puree | Dry Cherry Tomato | Pickled Chilli | 220g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenHSfour(true)}
            />
         
        
      </List>

      
    </Page>
        </>
    );
  };
  
  export default HotStarters;