import React from 'react';
import '../../details.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from "react-i18next";

import four from '../../images/main-course/4.png';
import fifteen from '../../images/main-course/15.png';
import sixteen from '../../images/main-course/16.png';









function MCfour({ closeModal }) {

    const { t } = useTranslation();
   
    const images = [
        fifteen,
        sixteen,
        four,
    ];
    
  return (
    <div className='modalBackgrounddetail'>
        <div className='modalContainerdetail'>
            <div className='titleCloseBtndetail'>
                <button onClick={() => closeModal(false)}> X </button>
            </div>

            
            
            <div className='body'>
            
                <p>{t('TENDERLOIN')}</p>
            </div>
            {/*<div className='footer'></div>*/}

                <Slide>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[1]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[2]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                </Slide>
            

            <div className='ingredients'>
               <strong>{t('Tenderloin grilled in Mibrisa charcoal grill | 250g')}</strong>
            </div>

        </div>
  </div>

  
  )
}

export default MCfour