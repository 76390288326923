import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './input.css';

import Main from './pages/Main';

import Breakfast from './pages/Breakfast';
import ColdStarters from './pages/ColdStarters';
import HotStarters from './pages/HotStarters';
import MainCourse from './pages/MainCourse';
import Bakery from './pages/Bakery';
import KidsMenu from './pages/KidsMenu';
import Desserts from './pages/Desserts';
import RedWines from './pages/RedWines';
import WhiteWines from './pages/WhiteWines';
import Food from './components/Food';
import WineCard from './components/WineCard';




import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    supportedLngs: ['en', 'sr', 'de', 'ru'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage','path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    }
  });

  export default i18n;




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route index element={<Main />} />
          <Route path="breakfast" element={<Breakfast />} />
          <Route path="coldstarters" element={<ColdStarters />} />
          <Route path="hotstarters" element={<HotStarters />} />
          <Route path="maincourse" element={<MainCourse />} />
          <Route path="bakery" element={<Bakery />} />
          <Route path="kidsmenu" element={<KidsMenu />} />
          <Route path="desserts" element={<Desserts />} />
          <Route path="red-wines" element={<RedWines />} />
          <Route path="white-wines" element={<WhiteWines />} />
          <Route path="food" element={<Food />} />
          <Route path="wine-card" element={<WineCard />} />
          
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);


