import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/bakery/1.png';
  import three from '../images/bakery/3.png';

  import Bone from './Bakery/Bone';
  import Btwo from './Bakery/Btwo';


  

const Bakery = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };
    
    const [openBone, setOpenBone] = useState(false);
    const [openBtwo, setOpenBtwo] = useState(false);

     


    return (
        <>
        
        <Page>
        {openBone && <Bone closeModal={setOpenBone}/>}
        {openBtwo && <Btwo closeModal={setOpenBtwo}/>}
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title={t('PIDE')}
            subtitle={t('maincourse')}
            after="16€"
            text={t('LAMB & BEEF | CHEESE & SPINACH | Each 300g')}
            media={
                <img
                src={one}
                alt="LAMB & BEEF | CHEESE & SPINACH | Each 300g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenBone(true)}
            />
        <ListItem
            title={t('BREAD')}
            subtitle={t('maincourse')}
            after="3€"
            text={t('PARATHA | ISRAELI PITA BREAD | BREADSTICKS | BREADSTICKS AND ISRAELI PITA BREAD | Each 150g')}
            media={
                <img
                src={three}
                alt="PARATHA | ISRAELI PITA BREAD | BREADSTICKS | Each 150g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenBtwo(true)}
            />
        
        
        
         
        
      </List>

      
    </Page>
        </>
    );
  };
  
  export default Bakery;