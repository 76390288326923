import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import lang from '../images/lang.png';

  import { useTranslation } from "react-i18next";
  import Modal from '../components/Modal';

  import bushwinesauvignonblanc from '../images/bela-vina/bush-wine-sauvignon-blanc.png';
  import villieracheninblanc from '../images/bela-vina/villiera-chenin-blanc.png';
  import villierajasmine from '../images/bela-vina/villiera-jasmine.png';
  import villierasauvignonblanc from '../images/bela-vina/villiera-sauvignon-blanc.png';
 

  

const WhiteWines = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/wine-card');
      };

     


    return (
        <>
        
        <Page>
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title="Bushwine sauvignon blanc"
            subtitle={t('whitewines')}
            after="30€"
            text="An intensely flavoured sauvignon blanc with a hints of green pepper,
            ig and flint on the nose. The palate is fresh and lively with a long
            finish and good balance."
            media={
                <img
                src={bushwinesauvignonblanc}
                alt="Artichoke soup"
                className="menu-photo"
                />
            }
            />    
        <ListItem
          title="Villiera chenin blanc"
          after="30€"
          subtitle={t('whitewines')}
          text="The wine has intense fruit with a hint of wood spice on the nose,
          including pineapple, guava and citrus. On the palate it is rich
          and full bodied with a good balance and long finish."
          media={
            <img
              src={villieracheninblanc}
              className="menu-photo"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Villiera jasmine"
          after="30€"
          subtitle={t('whitewines')}
          text="A floral, slightly spicy white wine which is fresh, light and slightly
          Sweet but complex enough to drink with food."
          media={
            <img
              src={villierajasmine}
              width="80"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Villiera sauvignon blanc"
          after="30€"
          subtitle={t('whitewines')}
          text="And intensely flavoured sauvignon blanc with hints of figs, green
          pepper tropical fruit."
          media={
            <img
              src={villierasauvignonblanc}
              width="80"
              alt="demo"
            />
          }
        />
        </List>
    </Page>
        </>
    );
  };
  
  export default WhiteWines;