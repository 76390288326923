import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import lang from '../images/lang.png';

  import { useTranslation } from "react-i18next";
  import Modal from '../components/Modal';

  import lambrusco from '../images/crvena-vina/lambrusco.jpg';
  import villieracabernetsauvignon from '../images/crvena-vina/villiera-cabernet-sauvignon.png';
  import villieramerlot from '../images/crvena-vina/villiera-merlot.png';
  import villierashiraz from '../images/crvena-vina/villiera-shiraz.jpg';
 

  

const RedWines = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/wine-card');
      };

     


    return (
        <>
        
        <Page>
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title="Lambrusco"
            subtitle={t('redwines')}
            after="30€"
            text="Smoked salmon, nile perch, tuna with olives, crackers,
            fresh lettuce and fresh lemon"
            media={
                <img
                src={lambrusco}
                alt="Artichoke soup"
                className="menu-photo"
                />
            }
            />    
        <ListItem
          title="Villiera cabernet sauvignon"
          after="30€"
          subtitle={t('redwines')}
          text="Cubes of avocado, fresh mango, fresh tomato with a touch
          of lemon dressing"
          media={
            <img
              src={villieracabernetsauvignon}
              className="menu-photo"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Villiera merlot"
          after="30€"
          subtitle={t('redwines')}
          text="Iceberg lettuce, tomato, cucumber, onions, green olives,
          bell peppers and feta cheese with vinaigrette dressing"
          media={
            <img
              src={villieramerlot}
              width="80"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Villiera shiraz"
          after="30€"
          subtitle={t('redwines')}
          text="Poached shrimp, avocado, tomato, onion, cilantro, scallion
          and fresh lemon dressing"
          media={
            <img
              src={villierashiraz}
              width="80"
              alt="demo"
            />
          }
        />
        </List>
    </Page>
        </>
    );
  };
  
  export default RedWines;