import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/kids-menu/1.png';
  import two from '../images/kids-menu/2.png';

  import KMone from './KidsMenu/KMone';
  import KMtwo from './KidsMenu/KMtwo';

  

const KidsMenu = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };
    
    const [openKMone, setOpenKMone] = useState(false);
    const [openKMtwo, setOpenKMtwo] = useState(false);

     


    return (
        <>
        
        <Page>
        {openKMone && <KMone closeModal={setOpenKMone}/>}
        {openKMtwo && <KMtwo closeModal={setOpenKMtwo}/>}
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title={t('CHICKEN & FRIES')}
            subtitle={t('maincourse')}
            after="8€"
            text={t('Crispy Chicken fingers | French Fries | Ketchup | Mayo | 200g')}
            media={
                <img
                src={one}
                alt="Crispy Chicken fingers | French Fries | Ketchup | Mayo | 200g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenKMone(true)}
            />
        <ListItem
            title={t('PIZZA')}
            subtitle={t('maincourse')}
            after="8€"
            text={t('Tomato sauce | Cheese | 250g')}
            media={
                <img
                src={two}
                alt="Tomato sauce | Cheese | 250g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenKMtwo(true)}
            />
        
        
        
         
        
      </List>

      
    </Page>
        </>
    );
  };
  
  export default KidsMenu;