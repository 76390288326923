import {useState} from 'react';
import { useNavigate} from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    List,
    ListItem,
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/main-course/1.png';
  import two from '../images/main-course/2.png';
  import three from '../images/main-course/3.png';
  import four from '../images/main-course/15.png';
  import five from '../images/main-course/5.png';
  import eight from '../images/main-course/8.png';
  import twelve from '../images/main-course/12.png';


  import MCone from './MainCourse/MCone';
  import MCtwo from './MainCourse/MCtwo';
  import MCthree from './MainCourse/MCthree';
  import MCfour from './MainCourse/MCfour';
  import MCfive from './MainCourse/MCfive';
  import MCsix from './MainCourse/MCsix';
  import MCseven from './MainCourse/MCseven';


  

const MainCourse = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };

    const [openMCone, setOpenMCone] = useState(false);
    const [openMCtwo, setOpenMCtwo] = useState(false);
    const [openMCthree, setOpenMCthree] = useState(false);
    const [openMCfour, setOpenMCfour] = useState(false);
    const [openMCfive, setOpenMCfive] = useState(false);
    const [openMCsix, setOpenMCsix] = useState(false);
    const [openMCseven, setOpenMCseven] = useState(false);
  

     


    return (
        <>
        
        <Page>
        {openMCone && <MCone closeModal={setOpenMCone}/>}
        {openMCtwo && <MCtwo closeModal={setOpenMCtwo}/>}
        {openMCthree && <MCthree closeModal={setOpenMCthree}/>}
        {openMCfour && <MCfour closeModal={setOpenMCfour}/>}
        {openMCfive && <MCfive closeModal={setOpenMCfive}/>}
        {openMCsix && <MCsix closeModal={setOpenMCsix}/>}
        {openMCseven && <MCseven closeModal={setOpenMCseven}/>}
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
            title={t('SEABASS FILE IN BANANA LEAF WITH PICKLED CAULIFLOWER')}
            subtitle={t('maincourse')}
            after="32€"
            text={t('Steamed seabass | Chilli jam | Pickled cauliflower | Cumin | 250g')}
            media={
                <img
                src={one}
                alt="Steamed seabass | Chilli jam | Pickled cauliflower | Cumin | 250g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCone(true)}
            />
        <ListItem
            title={t('MASGOUF HAMA WITH COCONUT RICE')}
            subtitle={t('maincourse')}
            after="28€"
            text={t('Hama filet | Masgouf dip | Jasmin rice | Coconut milk | 280g')}
            media={
                <img
                src={two}
                alt="Hama filet | Masgouf dip | Jasmin rice | Coconut milk | 280g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCtwo(true)}
            />
        <ListItem
            title={t('LAMB SHANK')}
            subtitle={t('maincourse')}
            after="42€"
            text={t('Lamb shank 48h sous vide | Creamy polenta | Red Wine sauce | 400g')}
            media={
                <img
                src={three}
                alt="Lamb shank 48h sous vide | Creamy polenta | Red Wine sauce | 400g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCthree(true)}
            />
        <ListItem
            title={t('TENDERLOIN')}
            subtitle={t('maincourse')}
            after="38€"
            text={t('Tenderloin grilled in Mibrisa charcoal grill | 250g')}
            media={
                <img
                src={four}
                alt="Tenderloin grilled in Mibrisa charcoal grill | 250g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCfour(true)}
            />
        <ListItem
            title={t('WAGYU RIBEYE')}
            subtitle={t('maincourse')}
            after="120€"
            text={t('Wagyu Ribeye grilled in Mibrisa charcoal grill | 350g')}
            media={
                <img
                src={five}
                alt="Wagyu Ribeye grilled in Mibrisa charcoal grill | 350g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCfive(true)}
            />
        <ListItem
            title={t('SIDES')}
            subtitle={t('maincourse')}
            after="8€"
            text={t('BATATA HARRA | BABY CARROTS | ASPARAGUS | COCONUT RICE | CREAMY POLENTA | Each 150g')}
            media={
                <img
                src={eight}
                alt="BATATA HARRA | BABY CARROTS | ASPARAGUS | COCONUT RICE | CREAMY POLENTA | Each 150g"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCsix(true)}
            />
        <ListItem
            title={t('SAUCES')}
            subtitle={t('maincourse')}
            after="5€"
            text={t('RED WINE SAUCE | TABBOULEH DIP | RED CABBAGE KETCHUP GREEN HERB MAYO | CHILLI JAM | Each 50ml')}
            media={
                <img
                src={twelve}
                alt="RED WINE SAUCE | TABBOULEH DIP | RED CABBAGE KETCHUP GREEN HERB MAYO | CHILLI JAM | Each 50ml"
                className="menu-photo"
                />
            }
            link
            onClick={() => setOpenMCseven(true)}
            />
        
        
         
        
      </List>

      
    </Page>
        </>
    );
  };
  
  export default MainCourse;