import {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import lang from '../images/lang.png';

  import { useTranslation } from "react-i18next";
  import Modal from '../components/Modal';


  import one from '../images/desserts/1.png';
  import two from '../images/desserts/2.png';

  import Done from './Desserts/Done';
  import Dtwo from './Desserts/Dtwo';
 

  

const Desserts = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState('tab-2');

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };
    
    const [openDone, setOpenDone] = useState(false);
    const [openDtwo, setOpenDtwo] = useState(false);

     


    return (
        <>
        
        <Page>
        {openDone && <Done closeModal={setOpenDone}/>}
        {openDtwo && <Dtwo closeModal={setOpenDtwo}/>}
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}
  

      
      <List>
        <ListItem
              title={t('KNAFEH | KÜNEFE')}
              subtitle={t('desserts')}
              after="11€"
              text={t('Kadaif | Lime syrup | Sweet cheese | Vanilla ice-cream | Pistachios | 180g')}
              media={
                  <img
                  src={one}
                  alt="Kadaif | Lime syrup | Sweet cheese | Vanilla ice-cream | Pistachios | 180g"
                  className="menu-photo"
                  />
              }
              link
              onClick={() => setOpenDone(true)}
              />
          <ListItem
              title={t('MUHALLEBI')}
              subtitle={t('desserts')}
              after="9€"
              text={t('Rosewater pudding | Wild pomegranate sauce | Pistachios | 150g')}
              media={
                  <img
                  src={two}
                  alt="Rosewater pudding | Wild pomegranate sauce | Pistachios | 150g"
                  className="menu-photo"
                  />
              }
              link
              onClick={() => setOpenDtwo(true)}
              />    
        </List>
    </Page>
        </>
    );
  };
  
  export default Desserts;