import React from 'react';
import '../../details.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from "react-i18next";

import one from '../../images/kids-menu/1.png';
import two from '../../images/kids-menu/2.png';









function KMone({ closeModal }) {

    const { t } = useTranslation();
   
    const images = [
        one,
        two,
    ];
    
  return (
    <div className='modalBackgrounddetail'>
        <div className='modalContainerdetail'>
            <div className='titleCloseBtndetail'>
                <button onClick={() => closeModal(false)}> X </button>
            </div>

            
            
            <div className='body'>
            
                <p>{t('CHICKEN & FRIES')}</p>
            </div>
            {/*<div className='footer'></div>*/}

            
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})`, 'backgroundSize': 'cover', 'backgroundPosition': 'center' }}>
                        
                    </div>
                </div>
                
            
            

            <div className='ingredients'>
               <strong>{t('Crispy Chicken fingers | French Fries | Ketchup | Mayo | 200g')}</strong>
            </div>

        </div>
  </div>

  
  )
}

export default KMone