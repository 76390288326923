import React, { useState, useRef } from 'react';
import { Link } from "react-router-dom";
import {
  Page,
  Navbar,
  NavbarBackLink,
  Tabbar,
  TabbarLink,
  Block,
  BlockTitle,
  Card,
  List,
  ListItem,
  Toggle,
  Button,
  Icon, 
  Popover
} from 'konsta/react';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';



import logo from '../images/square-portonovi-logo.png';
import breakfast from '../images/breakfast/1.png';
import coldstarters from '../images/cold-starters/1.png'
import hotstarters from '../images/hot-starters/1.png'
import maincourse from '../images/main-course/1.png';
import bakery from '../images/bakery/1.png';
import kidsmenu from '../images/kids-menu/1.png';
import desserts from '../images/desserts/2.png';


import jela from '../images/jela.png';
import pica from '../images/pica.png';
import vina from '../images/vina.png';
import lang from '../images/lang.png';

import redwines from '../images/red-wines.png';
import whitewines from '../images/white-wines.png';

import Modal from '../components/Modal';



import bacardi from '../images/pica/bacardi.jpg';
import lagavulin from '../images/pica/lagavulin.jpg';
import talisker from '../images/pica/talisker.jpg';
import tequilagold from '../images/pica/tequila-gold.jpg';




export default function WineCard() {
  const [activeTab, setActiveTab] = useState('tab-1');
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  

  

  


  return (

    <>
    <Page>
      <Navbar
        className='fixed logo'
        
        >
          
          <img src={logo}/>

          
            <div className='lang'><img className='openModalImg' src={lang} onClick={() => setOpenModal(true)}/></div>
            
            
          
          

        </Navbar>
        {openModal && <Modal closeModal={setOpenModal}/>}

        
      
        

      <Tabbar className="left-0 bottom-0 fixed">
          
          <TabbarLink
            active={activeTab === 'tab-1'}
            onClick={() => setActiveTab('tab-1')}
            label={t('food')}
            icon={
                <Icon
                  ios={<img className='w-6 h-6' src={jela}/>}
                  material={<img className='w-6 h-6' src={jela}/>}
                />
            }
          />
        {/*<TabbarLink
          active={activeTab === 'tab-2'}
          onClick={() => setActiveTab('tab-2')}
          label={t('drinks')}
          icon={
              <Icon
              ios={<img className='w-6 h-6' src={pica}/>}
              material={<img className='w-6 h-6' src={pica}/>}
            />
        }
        />
        <TabbarLink
          active={activeTab === 'tab-3'}
          onClick={() => setActiveTab('tab-3')}
          label={t('wine')}
          icon={
            <Icon
              ios={<img className='w-6 h-6' src={vina}/>}
              material={<img className='w-6 h-6' src={vina}/>}
            />
        }
      />*/}
      </Tabbar>

      

      

      {activeTab === 'tab-1' && (
        <div className="food-menu">

        <div className='food-card'>
          <Link to="/breakfast">
            <img src={breakfast}/>
            <div className='food-desc'>
              <p>{t('breakfast')}</p>
            </div>
            </Link>
        </div>

        <div className='food-card'>
          <Link to="/coldstarters">
            <img src={coldstarters}/>
            <div className='food-desc'>
              <p>{t('coldstarters')}</p>
            </div>
          </Link>
        </div>

        <div className='food-card'>
          <Link to="/hotstarters">
            <img src={hotstarters}/>
            <div className='food-desc'>
              <p>{t('hotstarters')}</p>
            </div>
          </Link>
        </div>

        <div className='food-card'>
          <Link to="/maincourse">
            <img src={maincourse}/>
            <div className='food-desc'>
              <p>{t('maincourse')}</p>
            </div>
          </Link>
        </div>

        <div className='food-card'>
          <Link to="/bakery">
            <img src={bakery}/>
            <div className='food-desc'>
              <p>{t('bakery')}</p>
            </div>
          </Link>
        </div>

        <div className='food-card'>
          <Link to="/kidsmenu">
            <img src={kidsmenu}/>
            <div className='food-desc'>
              <p>{t('kidsmenu')}</p>
            </div>
          </Link>
        </div>

        <div className='food-card'>
          <Link to="/desserts">
            <img src={desserts}/>
            <div className='food-desc'>
              <p>{t('desserts')}</p>
            </div>
          </Link>
        </div>
        
      </div>
      )}
      {activeTab === 'tab-2' && (
        <List>
        <ListItem
            title="Bacardi"
            subtitle={t('drinks')}
            after="30€"
            text=""
            media={
                <img
                src={bacardi}
                alt="Artichoke soup"
                className="menu-photo"
                />
            }
            />    
        <ListItem
          title="Lagavulin"
          after="30€"
          subtitle={t('drinks')}
          text=""
          media={
            <img
              src={lagavulin}
              className="menu-photo"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Talisker"
          after="30€"
          subtitle={t('drinks')}
          text=""
          media={
            <img
              src={talisker}
              width="80"
              alt="demo"
            />
          }
        />
        <ListItem
          title="Tequila gold"
          after="30€"
          subtitle={t('drinks')}
          text=""
          media={
            <img
              src={tequilagold}
              width="80"
              alt="demo"
            />
          }
        />
        </List>
      )}
      {activeTab === 'tab-3' && (
        <div className="food-menu">
        <div className='food-card'>
        <Link to="/red-wines">
          <img src={redwines}/>
          <div className='food-desc'>
            <p>{t('redwines')}</p>
          </div>
          </Link>
         
        </div>
      
        <div className='food-card'>
          <Link to="/white-wines">
            <img src={whitewines}/>
            <div className='food-desc'>
              <p>{t('whitewines')}</p>
            </div>
          </Link>
        </div>
      </div>
      )}

      
    </Page>


    </>
  );
}