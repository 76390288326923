import {useState} from 'react';
import { useNavigate, Link} from "react-router-dom";
import {
    Page,
    Navbar,
    NavbarBackLink,
    Tabbar,
    TabbarLink,
    Block,
    BlockTitle,
    Card,
    List,
    ListItem,
    Popup,
    Toggle,
    Button,
    Icon, 
    Popover
  } from 'konsta/react';
  import { useTranslation } from "react-i18next";


  import lang from '../images/lang.png';

  import Modal from '../components/Modal';

  import one from '../images/breakfast/1.png';
  import two from '../images/breakfast/2.png';
  import three from '../images/breakfast/3.png';
  import four from '../images/breakfast/4.png';
  import five from '../images/breakfast/5.png';


  import Bone from './BreakfastDetails/Bone';
  import Btwo from './BreakfastDetails/Btwo';
  import Bthree from './BreakfastDetails/Bthree';
  import Bfour from './BreakfastDetails/Bfour';
  import Bfive from './BreakfastDetails/Bfive';


  

const Breakfast = () => {
    const history = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const [openBone, setOpenBone] = useState(false);
    const [openBtwo, setOpenBtwo] = useState(false);
    const [openBthree, setOpenBthree] = useState(false);
    const [openBfour, setOpenBfour] = useState(false);
    const [openBfive, setOpenBfive] = useState(false);

    const { t } = useTranslation();

    const handleGoBack = (e) => {
        e.preventDefault();
        history('/');
      };

    

     


    return (
        <>
        
        <Page>
        {openBone && <Bone closeModal={setOpenBone}/>}
        {openBtwo && <Btwo closeModal={setOpenBtwo}/>}
        {openBthree && <Bthree closeModal={setOpenBthree}/>}
        {openBfour && <Bfour closeModal={setOpenBfour}/>} 
        {openBfive && <Bfive closeModal={setOpenBfive}/>} 
      <Navbar
      left={
        <NavbarBackLink text="Back" onClick={handleGoBack} />
      }
        right={<div className="lang"><img src={lang} onClick={() => setOpenModal(true)}/></div>}
        
        />
        {openModal && <Modal closeModal={setOpenModal}/>}

        
  

      
      <List>
        
          <ListItem
              title={t('SHAKSHUKA')}
              subtitle={t('breakfast')}
              after="9€"
              text={t('Poached eggs | Red vegetable mix | Homemade bread | 250g')}
              media={
                  <img
                  src={one}
                  alt="Poached eggs | Red vegetable mix | Homemade bread | 250g"
                  className="menu-photo"
                  />
                  
              }
              onClick={() => setOpenBone(true)}
              link
              />
              

            


        <ListItem
            title={t('BABA GHANOUSH CRISPY EGGS')}
            subtitle={t('breakfast')}
            after="9€"
            text={t('Crispy eggs | Homemade bread | Baba Ghanoush | Mix salad | 300g')}
            media={
                <img
                src={two}
                alt="Crispy eggs | Homemade bread | Baba Ghanoush | Mix salad | 300g"
                className="menu-photo"
                />
            }
            onClick={() => setOpenBtwo(true)}
            link
            />
            
        <ListItem
            title={t('SALMON “GRAVLAX” & AVOCADO')}
            subtitle={t('breakfast')}
            after="11€"
            text={t('Toasted bread | Dill yogurt | Salmon “Gravlax” | Avocado | 250g')}
            media={
                <img
                src={three}
                alt="Toasted bread | Dill yogurt | Salmon “Gravlax” | Avocado | 250g"
                className="menu-photo"
                />
            }
            onClick={() => setOpenBthree(true)}
            link
        />
        <ListItem
            title={t('OATS & DATES')}
            subtitle={t('breakfast')}
            after="8€"
            text={t('Oatmeal | Coconut milk | Date puree | Seasonal fruits | 250g')}
            media={
                <img
                src={four}
                alt="Oatmeal | Coconut milk | Date puree | Seasonal fruits | 250g"
                className="menu-photo"
                />
            }
            onClick={() => setOpenBfour(true)}
            link
        />
        <ListItem
            title={t('ROASTBEEF SCRAMBLED EGGS')}
            subtitle={t('breakfast')}
            after="10€"
            text={t('Scrambled eggs | Paratha bread | Turkish cacik | Roastbeef | 300g')}
            media={
                <img
                src={five}
                alt="Scrambled eggs | Paratha bread | Turkish cacik | Roastbeef | 300g"
                className="menu-photo"
                />
            }
            onClick={() => setOpenBfive(true)}
            link
        />     
      </List>

      
      

      
    </Page>
        </>
    );
  };
  
  export default Breakfast;