import React from 'react';
import '../components/Modal.css';

import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import gb from '../images/flags/united-kingdom.png';
import mne from '../images/flags/montenegro.png';
import de from '../images/flags/germany.png';






const languages = [
    {
      code: 'sr',
      name: 'Jelovnik',
      country_code: 'sr',
      //country_flag: <img className='flag' src={mne}/>
    },
    {
      code: 'en',
      name: 'Menu',
      country_code: 'gb',
      //country_flag: <img className='flag' src={gb}/>
    },
    {
      code: 'de',
      name: 'Speisekarte',
      country_code: 'de',
      //country_flag: <img className='flag' src={de}/>
    },
    {
      code: 'ru',
      name: 'Mеню',
      country_code: 'ru',
      //country_flag: <img className='flag' src={de}/>
    }
  ]

function Modal({ closeModal }) {
    const { t } = useTranslation();
    
    
  return (
    <div className='modalBackground'>
        <div className='modalContainer'>
            <div className='titleCloseBtn'>
                <button onClick={() => closeModal(false)}> X </button>
            </div>
            {/* <div className='title'>{t('Izaberite jezik')}</div> */}
            <div className='body'>
                <ul>
                {languages.map(({code, name, country_code, country_flag}) => (
                <li onClick={() => closeModal(false)} key={country_code}>
                    <button onClick={() => i18next.changeLanguage(code)}>
                    {/* {country_flag} */} {t(name)} 
                    </button>
                </li>
                ))} 
                
                </ul>
            </div>
            <div className='footer'></div>
        </div>
    </div>
  )
}

export default Modal